
function diashowCycle( id, options )
{
    $(function(){
        $('.diashow'+id).cycle(options);
    })
/*
      $('.diashow'+id).find('a').bind('click',function(){
        $('.diashow'+id).cycle('pause');
        $(window).bind('click',function(){
            $('.diashow'+id).cycle('resume');
            $(this).unbind('click');
        });
      })
         */
}


// setzt das startbild aus dem hash
function checkAnchor()
{
    hash = window.location.hash;
    if(hash) {
        result = hash.match(/#image(\d+)/);
        if( typeof result[1] != 'undefined' && parseInt(result[1]) > 0 && parseInt(result[1]) <= MaxIndex)
            return parseInt(result[1]);
    }
    return 0;
}

function getLocation()
{
    return window.location.href.replace(/#(.*)/,"");
}

function img_previous()
{
    img_switch_value( index - 1 );
}

function img_next()
{
    img_switch_value( index + 1 );
}

function img_first()
{
    img_switch_value( 0 );
}

function navigationPic( newValue )
{
    img_switch_value( index + newValue );
}

function updateNavigationImages()
{
    maxMiddle = max/2;
    for(var i=1; i <=max; i++ )
    {
        if(i <= maxMiddle)
        {
            navIndex = index - ( i );
        }
        else
        {
            navIndex = index + ( i - maxMiddle );
        }

        if( navIndex < 0 || navIndex > MaxIndex || typeof imageGalleryImgs[navIndex] == 'undefined' )
        {
            $("#image_gallery_dummy .navigationsbilder").find(".navimg"+i).parent().parent().css("display","none");
        }
        else
        {
            attribute = {
                src: imageGalleryImgs[navIndex].minipic
            };
            minipicImage = new Image();
            minipicImage.src = imageGalleryImgs[navIndex].minipic;
            if(minipicImage.width > 0 && minipicImage.height > 0)
            {
                attribute.width = minipicImage.width;
                attribute.height = minipicImage.height;
            }

            $("#image_gallery_dummy .navigationsbilder")
            .find(".navimg"+i)
            .attr(attribute)
            .parent()
            .attr("href",getLocation()+"#image"+navIndex)
            .parent()
            .css("display","");
        }
        // preload Images
        if( ( navIndex + i ) <= MaxIndex && ( navIndex + i ) > 0)
        {
            preloadNextimage = new Image();
            preloadNextimage.src = imageGalleryImgs[ ( navIndex + i ) ].minipic;
        }
    }
}

function img_switch_value(newValue, navigation_images)
{
    if(newValue < 0 || newValue > MaxIndex || typeof imageGalleryImgs[newValue] == 'undefined')
        return false;

    index = newValue;

    $('.currentTextHolder').html( (newValue+1) + '/' + (MaxIndex+1) );

    if(newValue == 0)
    {
        $("#image_gallery_dummy").find(".previous").css("display","none").find("a").attr("href",getLocation()+"#image"+newValue );
        $("#image_gallery_dummy").find(".go_previous").css("display","none").attr("href",getLocation()+"#image"+newValue);
    }
    else
    {
        $("#image_gallery_dummy").find(".previous").css("display","").find("a").attr("href",getLocation()+"#image"+newValue );
        $("#image_gallery_dummy").find(".go_previous").css("display","").attr("href",getLocation()+"#image"+newValue);
    }

    if(newValue == MaxIndex)
    {
        $("#image_gallery_dummy").find(".next").css("display","none").find("a").attr("href",getLocation()+"#image"+newValue );
        $("#image_gallery_dummy").find(".last").css("display","").find("a").attr("href",getLocation()+"#image0" );
        $("#image_gallery_dummy").find(".go_next").css("display","none").attr("href",getLocation()+"#image"+newValue);
    }
    else
    {
        $("#image_gallery_dummy").find(".next").css("display","").find("a").attr("href",getLocation()+"#image"+newValue );
        $("#image_gallery_dummy").find(".last").css("display","none").find("a").attr("href",getLocation()+"#image0" );
        $("#image_gallery_dummy").find(".go_next").css("display","").attr("href",getLocation()+"#image"+newValue);
    }

    $("#image_gallery_dummy").find(".image").fadeOut("slow");
    $("#image_gallery_dummy").fadeOut("slow").queue(function(){

        if(navigation_images > 0) {
            updateNavigationImages();
        }

        $(this).find(".image a.show_lightbox").attr({
            href: imageGalleryImgs[newValue].href,
            title: imageGalleryImgs[newValue].a_title,
            rel: newValue
        }).unbind('click').bind('click',function(event){
            var index = parseInt( $(this).attr('rel') );
            $('.invisible_galery a').eq(index).click();
            event.preventDefault();
            return false;
        });


        if( imageGalleryImgs[newValue].subtitle != '' )
        {
            $(this).find(".headline").html(imageGalleryImgs[newValue].subtitle).css("display","");
        }
        else
        {
            $(this).find(".headline").css("display","none");
        }

        if( imageGalleryImgs[newValue].subdescription != '' )
        {
            $(this).find(".description").html(imageGalleryImgs[newValue].subdescription).css("display","");
        }
        else
        {
            $(this).find(".description").css("display","none");
        }

        attribute = {
            src: imageGalleryImgs[newValue].src,
            title: imageGalleryImgs[newValue].img_title,
            alt: imageGalleryImgs[newValue].img_alt
        }
        galleryImage = new Image();
        galleryImage.src = imageGalleryImgs[newValue].src;
        if(galleryImage.width > 0 && galleryImage.height > 0)
        {
            attribute.width = galleryImage.width;
            attribute.height = galleryImage.height;
            $(this).find(".go_previous").css("height",galleryImage.height);
            $(this).find(".go_next").css("height",galleryImage.height);
        }

        // preload Images
        if( ( newValue + 1 ) <= MaxIndex )
        {
            preloadNextimage = new Image();
            preloadNextimage.src = imageGalleryImgs[ ( newValue + 1 ) ].minipic;
        }

        $(this).find(".image img").attr(attribute);
        $(this).find(".image").fadeIn("slow");
        $(this).fadeIn("slow");
        $(this).dequeue();
    });
    return false;
}

$(document).ready(function() {

    var step        = $('#gallery-list-outer').width();
    var maxWidth    = $('#gallery-list-inner').width();
    var left        = 0;

    function updateArrows() {

        if(left < 0) {
            $('#gallery-list-left').removeClass('disabled');
        } else {
            $('#gallery-list-left').addClass('disabled');
        }

        if(left - step > (maxWidth * -1)) {
            $('#gallery-list-right').removeClass('disabled');
        } else {
            $('#gallery-list-right').addClass('disabled');
        }
    }
    updateArrows();

    $('#gallery-list-left').click(function() {
        if(!$(this).hasClass('disabled')) {
            left += step;
            $('#gallery-list-inner').animate({
                'left': left
            }, 2500);
            updateArrows();
        }
    });

    $('#gallery-list-right').click(function() {
        if(!$(this).hasClass('disabled')) {
            left -= step;
            $('#gallery-list-inner').animate({
                'left': left
            }, 2500);
            updateArrows();
        }
    });

});

