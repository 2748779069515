(function($) {
    /**
     * Öffnet die Lightbox
     * @param obj Object a Tag, img Tag, canvas, jsonString
     */
    window.lightbox = function(obj) {

        if(typeof obj == "undefined") {
            return;
        }

        var loadedAjaxLinks = 0;

        /**
         * Ermittelt den Title Tag
         * @param $item jQuery Object
         * @param JSON json
         */
        var getTitle = function($item, json) {
            var title = "",
                titleName = null;
            if(json.valid) {
                for(key in json.data) {
                    if($item.attr('rel') == null || $item.attr('rel').indexOf('no-'+key) == -1) {
                        value = json.data[key];
                        if(key == 'title' && titleName != null) {
                            value = titleName;
                        }
                        title += '<span class="'+key+'">' + value + '</span>';
                    }
                }
            }
            return title;
        };

        /**
         * jQuery Deferred Function!
         * @param $item jQuery Object or string
         * @param callback function ( erhält ein Array mit href und titel )
         * @return Deferred.promise()
         */
        var getLightboxData = function($item, callback) {

            var dfd = $.Deferred(),
                ajaxData = null;

            // $item ist eine id
            if(typeof $item == "string" && $item.charAt(0) == '#') {
                $item = $($item);
            }

            if(typeof $item == "object" && $item.isJSON == true) {
                var title = "";
                for(key in $item.data) {
                    if($item.data[key] != null) {
                        title += '<span class="'+key+'">' + $item.data[key] + '</span>';
                    }
                }
                dfd.resolve();
                if(typeof callback == "function") {
                    callback([$item.src, title]);
                }
                loadedAjaxLinks++;
                return dfd.promise();
                // jQuery Object Array
            }else if($item.length > 1) {
                var imgNames = [];
                $.each($item, function(index,item){
                    var $it = $(item);
                    // img tags has no ajaxdata
                    if($it.is('a') && $it.find('img').length > 0) {
                        if($it.find('img').data('ajaxdata') == null) {
                            imgNames.push($it.find('img').attr('src'))
                        }
                    }else if($it.is('img') || $it.is('canvas')) {
                        if($it.data('ajaxdata') == null) {
                            imgNames.push($it.attr('src'))
                        }
                    }else {
                        if($it.data('ajaxdata') == null) {
                            imgNames.push($it.attr('href'))
                        }
                    }
                });

                if(imgNames.length == 0) {
                    dfd.resolve();
                    return dfd.promise();
                }

                imageName = imgNames.join(';');
            }else if($item.is('a')) {
                href = $item.attr('href');
                titleName = $item.find('img').attr('title');
                imageName = $item.find('img').attr('src');
                ajaxData = $item.find('img').data('ajaxdata');
            }else if($item.is('img')) {
                href = $item.attr('src').replace(/img_files\/([\d\w-]+)\//,"img_files/xxl/");
                titleName = $item.attr('title');
                imageName = $item.attr('src');
                ajaxData = $item.data('ajaxdata');
            }else if($item.is('canvas')) {
                href = $item.attr('src').replace(/img_files\/([\d\w-]+)\//,"img_files/xxl/");
                imageName = $item.attr('src');
                titleName = null;
                ajaxData = $item.data('ajaxdata');
            }


            if(ajaxData != null) {
                title = getTitle($item,ajaxData);
                dfd.resolve();
                if(typeof callback == "function") {
                    callback([href, title]);
                }
                loadedAjaxLinks++;
            }else {
                $.ajax({
                    type: 'POST',
                    url: 'ajax.php',
                    async: true,
                    data: {
                        'action': 'lightbox_getInfo',
                        'images': imageName
                    },
                    success: function(json) {
                        $.each(json, function(index,data){
                            var $item = $('[src="'+index+'"]');
                            title = getTitle($item,data);
                            $item.data('ajaxdata',data);
                            loadedAjaxLinks++;
                        })
                        dfd.resolve();

                        if(typeof callback == "function") {
                            callback([href, title]);
                        }
                    },
                    dataType: 'json'
                });
            }

            return dfd.promise();
        };

        /**
         * Übergibt die Links an die definierte Lightbox
         * @param links array
         * @param int clickedImageIndex
         * @return void
         */
        var openLightbox = function(links, clickedImageIndex) {
            var lightboxOptions = {
                'helpers': {
                    title : {
                        type : 'inside'
                    }
                },
                'titlePosition' : 'inside',
                'index': clickedImageIndex
            };


            if (links[0].href.indexOf('iframe') > -1) {
                lightboxOptions['type'] = 'iframe';
            }

            if (links.length == 1) {
                lightboxOptions['showNavArrows'] = false;
            }

            jQuery.fancybox(links, lightboxOptions);
        };

        /**
         * Wird beim $.when().then() aufgerufen
         */
        var defferedComplete = function(clickedImageIndex){

            // Items neuladen
            items = $('[rel="'+$item.attr('rel')+'"]');

            /*
             if(loadedAjaxLinks != items.length) {
             console.log(loadedAjaxLinks," != ",items.length);
             window.setTimeout(function(){
             defferedComplete(clickedImageIndex);
             },250);
             return;
             }
             */

            // Die zuvor geladen daten neu auslesen
            $.each(items,function(){
                getLightboxData($(this),function(data){
                    links.push({
                        'href'	: data[0],
                        'title'	: data[1]
                    });
                });
            });

            openLightbox(links, clickedImageIndex);
        }

        var links = [];
        var clickedImageIndex = 0;

        // Manueller aufruf über ein Json Object
        if(typeof obj == 'string' && jQuery.parseJSON(obj) != null) {
            var json = jQuery.parseJSON(obj);

            $.each(json,function(){
                this.isJSON = true;
                getLightboxData(this,function(data){
                    links.push({
                        'href': data[0],
                        'title': data[1]
                    })
                });
            });

            openLightbox(links, clickedImageIndex);
            return;
        }else {
            var $item = $(obj);
            var href,title,imageName;

            var lightboxRegex = /(.*?)\[([\w\d-_]+)\]/;
            var matchResult;

            // Galerie öffnen
            if($item.attr('rel') != null && (matchResult = $item.attr('rel').match(lightboxRegex))) {
                // Alle Galeriebilder
                var items = $('[rel="'+$item.attr('rel')+'"]');
                // Geklicktes Bild
                clickedImageIndex = $(items).index($item);

                // Alle Bilder daten laden mit der getLightboxData Funktion, und dann...
                $.when(getLightboxData(items)).then(function(){
                    defferedComplete(clickedImageIndex);
                });

                /*
                 var funcs = [];
                 $.each(items,function(index,element){
                 var id = 'image_'+matchResult[2]+'_'+index;
                 $(this).attr('id',id);
                 funcs.push('getLightboxData("#'+id+'")');
                 });
                 var data = funcs.join(", ");

                 // Alle Bilder daten laden mit der getLightboxData Funktion, und dann...
                 $.when(eval(data)).then(function(){
                 defferedComplete(clickedImageIndex)
                 });
                 */
            }
            // Einzelnes Bild
            else {
                getLightboxData($item,function(data){
                    links = [{
                        'href'	: data[0],
                        'title'	: data[1]
                    }];
                    openLightbox(links, clickedImageIndex);
                });
            }
        }
    }

    $(function(){


        $("a[rel^='lightbox'],img[class*='open_lightbox']").bind('click.lightbox',function(event){
            event.preventDefault();
            lightbox(this);
            return false;
        }).css('cursor','pointer');

    });

})(jQuery);
