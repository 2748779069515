$(document).ready(function() {

    console.log('test');

    $('.lang__dropbtn').on("click", function() {
        $('.lang__dropdown-content').slideToggle().toggleClass('open');
    });

    function iOS() {

        var iDevices = [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
        ];

        if (!!navigator.platform) {
            while (iDevices.length) {
                if (navigator.platform === iDevices.pop()){ return true; }
            }
        }

        return false;
    }

    //Menü-Icon animation auslösen
    $('.menu-icon').click(function () {
        $(this).toggleClass('open');
        $('.nav0-1').slideToggle('fast');
    });

    if($('.nav-left-fixer').innerHeight() > $('#content').innerHeight()){
        $('#content').css('min-height', $('.nav-left-fixer').innerHeight());
    }


    if(window.matchMedia('(max-width: 767px)').matches) {

        // Prüfen ob Untermenüpunkte existieren
        var submenuitem = $('.nav0-1 > ul li');
        submenuitem.find('a.sub').after('<span class="dropdown-icon visible-xs"><img alt="Dropdown-Icon" src="img/angle-down.svg"></span>');

        // Submenüebenen (de)aktivieren
        $('nav ul li span.dropdown-icon').click(function () {
            submenuitem.find('div.active').prev('span.dropdown-icon').removeClass('rotate');
            var sub = $(this).next('div');
            if (sub) {
                $(sub).toggleClass('active');
            }
            submenuitem.find('div.active').prev('span.dropdown-icon').addClass('rotate');
        });


        // Prüfen ob Untermenüpunkte existieren
        var submenuitem = $('.nav0-1 ul li');
        submenuitem.find('a.sub').after('<span class="dropdown-icon visible-xs"><img alt="Dropdown-Icon" src="img/angle-down.svg"></span>');

        // Submenüebenen (de)aktivieren
        $('.nav0-1 ul li span.dropdown-icon').click(function () {
            submenuitem.find('ul.active').prev('span.dropdown-icon').removeClass('rotate');
            var sub = $(this).next('ul');
            if (sub) {
                $(sub).toggleClass('active');
            }
            submenuitem.find('ul.active').prev('span.dropdown-icon').addClass('rotate');
        });
        // accordion

        $('.accordion__inc').on('click', function (){
            $(this).parent(".head").siblings().slideToggle('fast');
            $(this).hide();
            $(this).siblings('.accordion__dec').show();
        });
        $('.accordion__dec').on('click', function (){
            $(this).parent(".head").siblings().slideToggle('fast');
            $(this).hide()
            $(this).siblings('.accordion__inc').show();
        });
    }
    else if(window.matchMedia('(min-width: 768px)').matches){

        // Prüfen ob Untermenüpunkte existieren
        var submenuitem = $('nav ul li');
        submenuitem.find('a.sub').append('<span class="dropdown-icon visible-xs"><img alt="Dropdown-Icon" src="img/angle-down.svg"></span>');

        // Submenüebenen (de)aktivieren
        $('nav .nav0-1 > ul > li').hoverIntent({
            over: function () {
                var sub = $(this).find('a').next('div');
                if (sub) {
                    $(this).find('a').addClass('active');
                    $(sub).addClass('active');
                }
            },
            out: function() {
                var sub = $(this).find('a').next('div');
                if (sub) {
                    $(this).find('a').removeClass('active');
                    $(sub).removeClass('active');
                }
            },
            timeout: 400
        });

        // show more list
        $('.show-more__list').click(function () {
            $(this).prev().slideToggle();
            if($(this).text() === "mehr anzeigen...") {
                $(this).text('weniger anzeigen')
            }else  {
                $(this).text('mehr anzeigen...')
            }
        });
    }

    var desktopNav = $('.nav-top');
    var header = $('header');
    var fixBack = $('.fix-back');
    var logoOuter = $('.logo');
    var logo = $('.logo>img');
    var megadropdown = $('.megadropdown');
    var headerHeight;
    var desktopNavHeight;
    var logoOuterHeight;
    var logoHeight;
    var navSpaceToTop;
    var logoSpaceToTop;
    var contentOuter;
    var menuLeft;
    var overallHeight;
    var menuHeight;
    var offsetTop;



    setTimeout(function(){
        headerHeight = header.outerHeight() - 45;
        desktopNavHeight = desktopNav.outerHeight();
        logoOuterHeight = logoOuter.outerHeight();
        logoHeight = logo.outerHeight();

        navSpaceToTop = headerHeight-desktopNavHeight;
        logoSpaceToTop = headerHeight-logoHeight-((logoOuterHeight-logoHeight+(headerHeight-navSpaceToTop-logoHeight))/2);

        //Menü links
        if($('.nav-left-fixer').length > 0) {
            contentOuter = $('.nav-left-fixer').parents('.container-outer');
            menuLeft = $('.nav-left-fixer');

            overallHeight = contentOuter.height();
            menuHeight = menuLeft.height();
            offsetTop = contentOuter.offset().top - desktopNavHeight;
        }
    }, 50);



    windowWidth = $(window).outerWidth();

    $(window).resize(function(){
        if(windowWidth > $(window).outerWidth() || windowWidth < $(window).outerWidth()) {
            windowWidth = $(window).outerWidth();
            headerHeight = header.outerHeight();
            desktopNavHeight = desktopNav.outerHeight();
            logoOuterHeight = logoOuter.outerHeight();
            logoHeight = logo.outerHeight();

            navSpaceToTop = headerHeight-desktopNavHeight;
            logoSpaceToTop = headerHeight-logoHeight-((logoOuterHeight-logoHeight+(headerHeight-navSpaceToTop-logoHeight))/2);

            //Menü links
            if($('.nav-left-fixer').length > 0) {
                overallHeight = contentOuter.height();
                menuHeight = menuLeft.height();
                offsetTop = contentOuter.offset().top - desktopNavHeight;
            }
        }
    });


    $(window).scroll(function() {
        var scrollPosToTop = $(this).scrollTop();

        /*
                if(iOS()) {
                    //Header
                    if (scrollPosToTop > headerHeight) {
                        fixBack.addClass('desktop-fixed');
                        fixBack.css('top', -navSpaceToTop);
                        logoOuter.addClass('desktop-fixed');
                        logoOuter.css({
                            'margin-top': -5,
                            'top': 0
                        });
                        megadropdown.css({
                            'padding-top': 1
                        })
                    }
                    else {
                        fixBack.removeClass('desktop-fixed');
                        fixBack.css('top', 0);
                        logoOuter.removeClass('desktop-fixed');
                        logoOuter.css({
                            'margin-top': 19,
                            'padding': ''
                        });
                        megadropdown.css({
                            'padding-top': 20
                        })
                    }
                } else {
                    //Header
                    if ( scrollPosToTop > navSpaceToTop) {
                        fixBack.addClass('desktop-fixed');
                        fixBack.css('top', -navSpaceToTop);
                        logoOuter.css({
                            'margin-top': 40,
                            'top': 0
                        });

                        desktopNav.css({
                            'margin-top': 47
                        })
                        megadropdown.css({
                            'padding-top': 1
                        })
                    }
                    else {
                        fixBack.removeClass('desktop-fixed');
                        fixBack.css('top', 0);
                        logoOuter.css({
                            'margin-top': 19,
                            'padding': ''
                        });
                        desktopNav.css({
                            'margin-top': 25
                        })
                        megadropdown.css({
                            'padding-top': 20
                        })
                    }
                }
        */

        if ( scrollPosToTop > 50) {
            fixBack.addClass('desktop-fixed');
            fixBack.css('top', -15);
        } else {
            fixBack.removeClass('desktop-fixed');
            fixBack.css('top', 0);
        }

        //Linkes Menü
        if($('.nav-left-fixer').length > 0) {
            var pullUp = scrollPosToTop + menuHeight;
            var diff = (overallHeight + offsetTop) - pullUp;



            if (scrollPosToTop > (offsetTop) && diff < 0) {
                // Wenn Contentende erreicht, das Menü entsprechend der ab hier gescrollten Pixelzahl nach oben schieben
                toTop = diff + desktopNavHeight;
                menuLeft.css({
                    "position": "fixed",
                    "top": toTop + "px"
                });
            } else if (scrollPosToTop > offsetTop) {
                // Wenn die in offsetTop erreichte Strecke nach unten gescrollt wurde, das Menü anpinnen
                menuLeft.css({
                    "position": "fixed",
                    "top": desktopNavHeight + "px"
                });
            } else {
                // Menü an ursprünglicher Stelle relativ positionieren
                menuLeft.css({"position": "absolute", "top": "auto"});
            }
        }
        // }

    });


    if($('.start-slider').length > 0) {
        $('.start-slider').slick({
            dots: true,
            arrows: true,
            fade: true,
            prevArrow: '<svg class="slick-prev" xmlns="http://www.w3.org/2000/svg" width="13.271" height="24.541" viewBox="0 0 13.271 24.541"><defs><style>.a{fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}</style></defs><path class="a" d="M419.93-1683.605l-10.856,10.856,10.856,10.856" transform="translate(-408.074 1685.02)"/></svg>',
            nextArrow: '<svg class="slick-next" xmlns="http://www.w3.org/2000/svg" width="13.271" height="24.541" viewBox="0 0 13.271 24.541"><defs><style>.a{fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}</style></defs><path class="a" d="M409.074-1683.605l10.856,10.856-10.856,10.856" transform="translate(-407.659 1685.02)"/></svg>',
            responsive: [
                {
                    breakpoint: 550,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        dots: true,
                        arrows: true
                    }
                }
            ]
        });
    }

    if($('.content-slider--f').length > 0) {
        $('.content-slider--f').slick({
            dots: false,
            arrows: true,
            prevArrow: '<svg class="slick-prev" xmlns="http://www.w3.org/2000/svg" width="13.271" height="24.541" viewBox="0 0 13.271 24.541"><defs><style>.a{fill:none;stroke:#2aa6df;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}</style></defs><path class="a" d="M419.93-1683.605l-10.856,10.856,10.856,10.856" transform="translate(-408.074 1685.02)"/></svg>',
            nextArrow: '<svg class="slick-next" xmlns="http://www.w3.org/2000/svg" width="13.271" height="24.541" viewBox="0 0 13.271 24.541"><defs><style>.a{fill:none;stroke:#2aa6df;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}</style></defs><path class="a" d="M409.074-1683.605l10.856,10.856-10.856,10.856" transform="translate(-407.659 1685.02)"/></svg>',
            speed: 300,
            slidesToShow: 3,
            slidesToScroll: 3,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: true,
                        arrows: false
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        arrows: true
                    }
                },
                {
                    breakpoint: 550,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        dots: false,
                        arrows: true
                    }
                } //,
                // {
                //     breakpoint: 350,
                //     settings: {
                //         slidesToShow: 1,
                //         slidesToScroll: 1,
                //         arrows: false
                //     }
                // }
            ]
        });
    }


    if($('.content-slider--t').length > 0) {
        $('.content-slider--t').slick({
            dots: false,
            arrows: true,
            speed: 300,
            slidesToShow: 3,
            slidesToScroll: 3,
            prevArrow: '<svg class="slick-prev" xmlns="http://www.w3.org/2000/svg" width="13.271" height="24.541" viewBox="0 0 13.271 24.541"><defs><style>.a{fill:none;stroke:#2aa6df;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}</style></defs><path class="a" d="M419.93-1683.605l-10.856,10.856,10.856,10.856" transform="translate(-408.074 1685.02)"/></svg>',
            nextArrow: '<svg class="slick-next" xmlns="http://www.w3.org/2000/svg" width="13.271" height="24.541" viewBox="0 0 13.271 24.541"><defs><style>.a{fill:none;stroke:#2aa6df;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}</style></defs><path class="a" d="M409.074-1683.605l10.856,10.856-10.856,10.856" transform="translate(-407.659 1685.02)"/></svg>',
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: true,
                        arrows: true
                    }
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        arrows: true
                    }
                },
                {
                    breakpoint: 550,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        dots: false,
                        arrows: true
                    }
                }
            ]
        });
    }

    if($('.cert-slider').length > 0) {
        $('.cert-slider').slick({
            dots: false,
            arrows: false,
            slidesToShow: 4,
            slidesToScroll: 4,
            responsive: [
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        });
    }



    $(".fancybox").fancybox();

    if ($(".content-slider--prod").length > 0) {

        var itemsCount = $(".content-slider--prod").data("items");

        if (itemsCount === undefined) {
            itemsCount = 3;
        }

        $('.content-slider--prod').slick({
            dots: true,
            arrows: true,
            nextArrow: '<svg class="slick-next" xmlns="http://www.w3.org/2000/svg" width="13.271" height="24.541" viewBox="0 0 13.271 24.541"><defs><style>.a{fill:none;stroke:#2aa6df;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}</style></defs><path class="a" d="M409.074-1683.605l10.856,10.856-10.856,10.856" transform="translate(-407.659 1685.02)"/></svg>',
            prevArrow: '<svg class="slick-prev" xmlns="http://www.w3.org/2000/svg" width="13.271" height="24.541" viewBox="0 0 13.271 24.541"><defs><style>.a{fill:none;stroke:#2aa6df;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}</style></defs><path class="a" d="M419.93-1683.605l-10.856,10.856,10.856,10.856" transform="translate(-408.074 1685.02)"/></svg>',
            autoplay: true,
            autoplaySpeed: 4500,
            speed: 600,
            slidesToShow: itemsCount,
            slidesToScroll: itemsCount,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: itemsCount,
                        slidesToScroll: itemsCount,
                        infinite: true,
                        dots: true,
                        arrows: true
                    }
                },
                {
                    breakpoint: 550,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        arrows: true,
                        dots: false
                    }
                }
            ]
        });

        $('.content-slider--prod:not(.alone)').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
            setSlider(nextSlide);
        });

        function setProdSlider(currentSlide) {
            var old = $('.content-slider--prod:not(.alone)').slick('slickCurrentSlide');

            if( old != currentSlide*itemsCount) {
                $('.content-slider--prod:not(.alone)').slick('slickGoTo',currentSlide*itemsCount);
            }
        }
    }

    if ($(".slider").length > 0) {

        $('.slider:not(.alone)').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
            setProdSlider(nextSlide);
        });

        function setSlider(currentSlide) {
            var old = $('.slider:not(.alone)').slick('slickCurrentSlide');
            if (old != Math.floor(currentSlide / itemsCount)) {
                $('.slider:not(.alone)').slick('slickGoTo', currentSlide / itemsCount);
            }
        }

    }

    if ($(".prod-slider").length > 0) {

        $('.prod-slider').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
            arrows: false,
            adaptiveHeight: true
        });
    }


    $('.search--btn').click(function() {
        if ($(this).hasClass(open)) {

        } else {
            $('.search .searchterm').focus();
        }
        $(this).toggleClass('open');
    });


    var bundeslaender = $("svg.deutschland > *");

    $(document).on('click','path',function(){
        bundeslaender.removeClass("focused");
        $(this).addClass("focused");

    });

    $("#Hessen").click(function(){
        $(".ansprechpartner-items").addClass("notvis");
        $.each(ansprech[7], function(key, value) {
            var ap = ".ansprech-" + value;
            $(ap).removeClass("notvis");
        });
    });

    $("#Niedersachsen").click(function(){
        $(".ansprechpartner-items").addClass("notvis");
        $.each(ansprech[9], function(key, value) {
            var ap = ".ansprech-" + value;
            $(ap).removeClass("notvis");
        });
    });

    $("#Nordrhein-Westfalen").click(function(){
        $(".ansprechpartner-items").addClass("notvis");
        $.each(ansprech[10], function(key, value) {
            var ap = ".ansprech-" + value;
            $(ap).removeClass("notvis");
        });
    });
    $("#Rheinland-Pfalz").click(function(){
        $(".ansprechpartner-items").addClass("notvis");
        $.each(ansprech[11], function(key, value) {
            var ap = ".ansprech-" + value;
            $(ap).removeClass("notvis");
        });
    });
    $("#Thüringen").click(function(){
        $(".ansprechpartner-items").addClass("notvis");
        $.each(ansprech[16], function(key, value) {
            var ap = ".ansprech-" + value;
            $(ap).removeClass("notvis");
        });
    });
    $("#Sachsen").click(function(){
        $(".ansprechpartner-items").addClass("notvis");
        $.each(ansprech[13], function(key, value) {
            var ap = ".ansprech-" + value;
            $(ap).removeClass("notvis");
        });
    });
    $("#Bayern").click(function(){
        $(".ansprechpartner-items").addClass("notvis");
        $.each(ansprech[2], function(key, value) {
            var ap = ".ansprech-" + value;
            $(ap).removeClass("notvis");
        });
    });
    $("#Brandenburg").click(function(){
        $(".ansprechpartner-items").addClass("notvis");
        $.each(ansprech[4], function(key, value) {
            var ap = ".ansprech-" + value;
            $(ap).removeClass("notvis");
        });
    });
    $("#Bremen").click(function(){
        $(".ansprechpartner-items").addClass("notvis");
        $.each(ansprech[5], function(key, value) {
            var ap = ".ansprech-" + value;
            $(ap).removeClass("notvis");
        });
    });
    $("#Hamburg").click(function(){
        $(".ansprechpartner-items").addClass("notvis");
        $.each(ansprech[6], function(key, value) {
            var ap = ".ansprech-" + value;
            $(ap).removeClass("notvis");
        });
    });
    $("#Berlin").click(function(){
        $(".ansprechpartner-items").addClass("notvis");
        $.each(ansprech[3], function(key, value) {
            var ap = ".ansprech-" + value;
            $(ap).removeClass("notvis");
        });
    });
    $("#Baden__x26__Württemberg").click(function(){
        $(".ansprechpartner-items").addClass("notvis");
        $.each(ansprech[1], function(key, value) {
            var ap = ".ansprech-" + value;
            $(ap).removeClass("notvis");
        });
    });
    $("#Saarland").click(function(){
        $(".ansprechpartner-items").addClass("notvis");
        $.each(ansprech[12], function(key, value) {
            var ap = ".ansprech-" + value;
            $(ap).removeClass("notvis");
        });
    });
    $("#Sachsen-Anhalt").click(function(){
        $(".ansprechpartner-items").addClass("notvis");
        $.each(ansprech[14], function(key, value) {
            var ap = ".ansprech-" + value;
            $(ap).removeClass("notvis");
        });
    });
    $("#Schleswig-Holstein").click(function(){
        $(".ansprechpartner-items").addClass("notvis");
        $.each(ansprech[15], function(key, value) {
            var ap = ".ansprech-" + value;
            $(ap).removeClass("notvis");
        });
    });
    $("#Mecklenburg-Vorpommern").click(function(){
        $(".ansprechpartner-items").addClass("notvis");
        $.each(ansprech[8], function(key, value) {
            var ap = ".ansprech-" + value;
            $(ap).removeClass("notvis");
        });
    });

    $('.imgmap-info').hover(function(){
        $('.imgmap-luftbild .filter').toggleClass('active');
    });

    /*
     $('img[usemap]').rwdImageMaps();

     $('#imgmap-info1')
     .hover(function(){
     $('.imgmap-infobox.box1').toggleClass('visible');
     })
     .click(function(){
     $('.imgmap-infobox.box1').toggleClass('visible');
     });
     $('#imgmap-info2')
     .hover(function(){
     $('.imgmap-infobox.box2').toggleClass('visible');
     })
     .click(function(){
     $('.imgmap-infobox.box2').toggleClass('visible');
     });
     $('#imgmap-info3')
     .hover(function(){
     $('.imgmap-infobox.box3').toggleClass('visible');
     })
     .click(function(){
     $('.imgmap-infobox.box3').toggleClass('visible');
     });
     $('#imgmap-info4')
     .hover(function(){
     $('.imgmap-infobox.box4').toggleClass('visible');
     })
     .click(function(){
     $('.imgmap-infobox.box4').toggleClass('visible');
     });
     $('#imgmap-info5')
     .hover(function(){
     $('.imgmap-infobox.box5').toggleClass('visible');
     })
     .click(function(){
     $('.imgmap-infobox.box5').toggleClass('visible');
     });
     $('#imgmap-info6')
     .hover(function(){
     $('.imgmap-infobox.box6').toggleClass('visible');
     })
     .click(function(){
     $('.imgmap-infobox.box6').toggleClass('visible');
     });
     $('#imgmap-info7')
     .hover(function(){
     $('.imgmap-infobox.box7').toggleClass('visible');
     })
     .click(function(){
     $('.imgmap-infobox.box7').toggleClass('visible');
     });
     */

});

